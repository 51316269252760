import React, { useEffect } from 'react'
import { Layout } from '../../components/AppLayout'
import styled from 'styled-components'
import { AppSourcingEventsBanner } from '../../components/AppSourcingEventsBanner'
import { AppSourcingEventsCards } from '../../components/AppSourcingEventsCards'
import { AppSourcingEventsCollapsible } from '../../components/AppSourcingEventsCollapsible'
import Scroll from 'react-scroll'
import { RDHelmet } from '../../components/RDHelmet'
import { AppWWOFSourcing } from '../../components/AppWWOFSourcing'
import { AppCustomersReviewSection } from '../../components/AppCustomersReviewSection'
var scroll = Scroll.animateScroll

const scrollData = {
  ByCompany: 700,
  FreeInstance: 900,
  SmallEnterprise: 1500,
  MediumEnterprise: 3000,
  LargeEnterprise: 4300,
  ByRoles: 5700,
  Executives: 5900,
  Procurement: 7400,
  Finance: 8600,
}

const scrollToIndex = (location: string) => {
  const item = Object.entries(scrollData).find((item) => item[0] == location)
  if (item && item[1]) {
    scroll.scrollTo(item[1])
  }
}

const SourcingEventsWrapper = styled.div`
  body: {
    overflow-x: hidden;
    overflow-y: scroll;
  }
`

const SourcingEvents = (props: any) => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110 United States',
      postalCode: '95054',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  useEffect(() => {
    if (props.location.state && props.location.state.scroll) {
      scrollToIndex(props.location.state.scroll)
    }
  }, [])

  return (
    <Layout scrollToIndex={scrollToIndex}>
      <RDHelmet
        subtitle={'Sourcing Events'}
        contentDescription="Optimize sourcing events with Raindrop: streamline procurement, boost efficiency, and drive cost savings effortlessly."
        contentKeywords="content='Sourcing Automation, Procurement Technology, Spend Management, Source-to-Pay (S2P), Strategic Sourcing, Supplier Collaboration, Contract Management, AI-Powered Procurement, Workflow Optimization, Spend Visibility, Supplier Scorecards, Procurement Analytics, Risk Mitigation, Supply Chain Digitization, Collaboration Tools'"
        contentOG="AI Powered Source-to-Pay SaaS Platform"
        contentOgDescription="Optimize sourcing events with Raindrop: streamline procurement, boost efficiency, and drive cost savings effortlessly."
        contentOgUrl="https://raindrop.com/solutions/SourcingEvents/"
        schemaMarkup={schemaMarkup}
      />
      <SourcingEventsWrapper>
        <AppSourcingEventsBanner />
        <AppWWOFSourcing />
        <AppCustomersReviewSection />
        <AppSourcingEventsCards />
        <AppSourcingEventsCollapsible />
      </SourcingEventsWrapper>
    </Layout>
  )
}

export default SourcingEvents
